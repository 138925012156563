import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import './style.scss';

function SubscribeForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    const { result, msg } = await addToMailchimp(email, { FNAME: name });
    result === 'success' && setEmail('') && setName('');
    // Removes the HTML returned in some response messages in case of error
    setMessage(msg.split('<')[0]);
    setStatus(result);
  };

  const handleChangeName = event => setName(event.target.value);
  const handleChangeEmail = event => setEmail(event.target.value);

  return (
    <form className="subscribe-form">
      <div className="subscribe-form__content">
        <input
          className="subscribe-form__input"
          type="text"
          onChange={handleChangeName}
          value={name}
          placeholder="Tu nombre"
          required
        />
        <input
          className="subscribe-form__input"
          type="email"
          onChange={handleChangeEmail}
          value={email}
          placeholder="Tu email"
          required
        />

        <button
          className="subscribe-form__button"
          type="submit"
          onClick={handleSubmit}
        >
          ¡Subscribirme!
      </button>
      </div>
      <span
        status={status}
        className={
          status === 'success'
            ? 'subscribe-form__message_success'
            : 'subscribe-form__message_failure'
        }
      >
        {message}
      </span>
    </form>
  );
}

export default SubscribeForm;